import React from 'react'
import styled from '@emotion/styled'

import ButtonLink from '../../components/Buttons'
import { GridContainer, ContentContainer } from '../../components/Grid'
import Section from '../../components/Section'
import { H1, Paragraph } from '../../components/Typography'

import { Colors, Gutter } from '../../utils/styles'

const StyledH1 = styled(H1)({
  color: Colors.PRIMARY,
})

const StyledParagraph = styled(Paragraph)({
  color: Colors.PRIMARY,
  fontWeight: 500,
})

const TextContainer = styled.div({
  marginBottom: Gutter.FULL,
})

const PageNotFoundPageLayout = () => (
  <Section verticalPadding="140px">
    <GridContainer>
      <ContentContainer>
        <TextContainer>
          <StyledH1>404 Page Not Found</StyledH1>
          <StyledParagraph large>
            Sorry, the page you were looking for doesn&#39;t exist.
          </StyledParagraph>
        </TextContainer>
        <ButtonLink to="/">Return Home</ButtonLink>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default PageNotFoundPageLayout
