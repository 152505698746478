import React from 'react'

import LayoutErrorPage from '../pageLayouts/LayoutErrorPage'

const NotFoundPage = () => (
  <div>
    <LayoutErrorPage />
  </div>
)

export default NotFoundPage
